// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-base-page-base-page-jsx": () => import("./../../../src/pages/BasePage/BasePage.jsx" /* webpackChunkName: "component---src-pages-base-page-base-page-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-posts-list-posts-list-jsx": () => import("./../../../src/pages/PostsList/PostsList.jsx" /* webpackChunkName: "component---src-pages-posts-list-posts-list-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-posts-list-by-tag-jsx": () => import("./../../../src/templates/PostsListByTag.jsx" /* webpackChunkName: "component---src-templates-posts-list-by-tag-jsx" */),
  "component---src-templates-tags-list-jsx": () => import("./../../../src/templates/TagsList.jsx" /* webpackChunkName: "component---src-templates-tags-list-jsx" */)
}

